export default function iosEvent(event) {
  const eventsInterface = getInterface();
  if (!eventsInterface) {
    // eslint-disable-next-line no-console
    console.warn('[IOS_SEND_EVENT_WARNING]', event);
    return;
  }
  eventsInterface.postMessage(event);
}

function getInterface() {
  if (window.webkit?.messageHandlers?.ListenEventsInterface)
    return window.webkit?.messageHandlers?.ListenEventsInterface;

  return null;
}
