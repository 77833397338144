import React from 'react';
import PropTypes from 'prop-types';
import actionManager from '@app/pages/completedOrder/actions';
import AnimatedElement from '@app/animations/AnimatedElement';
import { AppearAnimation, UseAppearFromBottomToTop } from '@app/animations/appear';
import { FadeAnimation, UseFadeIn } from '@app/animations/fade';
import pageEventService, { PAGE_EVENT } from '@app/services/page-event-service';

import Button from './Button';
import { SIZE } from './constants';
import { Image } from '@pedidosya/order-status-components';

const propTypes = {
  brickData: PropTypes.object,
};

function ButtonFactory({ brickData }) {
  const { variant, data, actions, styles, order } = brickData;
  const handler = actionManager.buildHandler(actions);
  const hasRedirectEvent =
    actions.filter((action) => action.type === 'redirect').length > 0 || false;

  const onButtonClicked = () => {
    pageEventService.fireEvent(PAGE_EVENT.BEFORE_UNLOAD_PAGE, () => {
      // execute each action
      handler();
      if (hasRedirectEvent) {
        setTimeout(() => {
          // because native view is not really navigating to a new page
          // here it will wait 2 seconds to make a hard refresh and show
          // the animation again.
          window.location.reload();
        }, 2000);
      }
    });
  };
  const animationDuration = `${order / 2 || 0}s`;
  const animationDefinitions = [AppearAnimation, FadeAnimation];
  const animationUses = [
    UseAppearFromBottomToTop.configure({ delay: animationDuration }),
    UseFadeIn.configure({ delay: animationDuration }),
  ];

  const initialElementStyles = { opacity: 0 };

  const textDecoration = styles['textDecoration'];
  const isUnderlinedText = textDecoration && textDecoration === 'underline';
  return (
    <AnimatedElement
      animationDefinitions={animationDefinitions}
      animationUses={animationUses}
      styles={initialElementStyles}
    >
      <Button
        icon={
          data?.icon ? (
            <Image src={data?.icon} alt={data.text} title={data.text} margin={[0, 4, 0, 0]} />
          ) : undefined
        }
        underlined={isUnderlinedText}
        order={data.order}
        text={data.text}
        style={{ size: SIZE.LARGE, variant }}
        onClick={onButtonClicked}
      />
    </AnimatedElement>
  );
}

ButtonFactory.propTypes = propTypes;

export default ButtonFactory;
