// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import factory from './factory';

const propTypes = {
  data: PropTypes.object,
};

function Brick({ customProps, data, order }) {
  return factory.create({ ...data, order, customProps });
}

Brick.propTypes = propTypes;

export default Brick;
