function getClientQueryParamsObject(allowedPropList = []) {
  const queryParams = new URLSearchParams(window.location.search);

  if (Array.isArray(allowedPropList) && allowedPropList.length > 0) {
    return allowedPropList.reduce((accum, key) => {
      const value = queryParams.get(key);
      if (value) {
        accum[key] = value;
      }
      return accum;
    }, {});
  }

  return {};
}

export default getClientQueryParamsObject;
