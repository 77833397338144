const deepLinkMatchers = [
  {
    matcher: 'pedidosya://feedback',
    url: '/feedback/web', //TODO: esta url de feedback la estan trabajando, no esta disponible aun 02/05/2022
  },
  {
    matcher: 'pedidosya://helpcenter',
    url: '/customer-service', //TODO: esta url de feedback la estan trabajando, no esta disponible aun 02/05/2022
  },
  {
    matcher: 'pedidosya://onlinehelp',
    url: '/customer-service', //TODO: esta url de feedback la estan trabajando, no esta disponible aun 02/05/2022
  },
];

function mapDeepLinkToWebLink(deeplink) {
  const target = deepLinkMatchers.find(({ matcher }) => deeplink && deeplink.startsWith(matcher));
  if (target) {
    return deeplink.replace(target.matcher, target.url);
  }
  return '';
}

export default function webEvent(context) {
  if (context?.event === 'redirect') {
    const url = mapDeepLinkToWebLink(context?.payload?.target);
    if (url) {
      window.location.href = url;
    }
  }
}
