import styled from 'styled-components';
import { SlideAnimation, UseSlideDown, UseSlideUp } from '@app/animations/slide';
import { Container as OSContainer } from '@pedidosya/order-status-components';

export const Media = styled.div``;

export const Container = styled.div`
  height: 280px;
  position: relative;

  margin-bottom: 0;
  overflow: visible;
  width: 100%;

  & > ${Media} {
    z-index: 2;
    width: 100%;
    max-width: 480px;
    height: 360px;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  & svg {
    width: 100% !important;
  }

  @media screen and (min-width: 355px) {
    margin-bottom: 50px !important;
  }
  @media screen and (min-width: 410px) {
    margin-bottom: 130px !important;
  }
`;

export const BackgroundContainer = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 220px;
  ${SlideAnimation}

  & > .bg {
    position: absolute;
    background-color: ${({ theme }) => theme.color('shape-color-surface-feedback-positive')};
    top: 0px;
    transform: translateX(-50%);
    border-radius: 0 0 50% 50%;
    left: 50%;
    width: 150%;
    height: 100%;
    z-index: 1;
  }

  & > .slideDown {
    ${UseSlideDown.configure({ duration: '.2s' }).getAsCss()}
    animation-duration: 0.5s;
  }
  & > .slideUp {
    ${UseSlideUp.configure({ duration: '.2s' }).getAsCss()}
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }

  @media screen and (min-width: 355px) {
    height: 280px;
  }

  @media screen and (min-width: 410px) {
    height: 300px;
  }
`;

