import styled from 'styled-components';
import {
  AppearAnimation,
  UseAppearFromBottomToTop,
} from '@app/animations/appear';
import { FadeAnimation, UseFadeIn } from '@app/animations/fade';
import { mergeAnimationUsesOptions } from '@app/animations/AnimatedElement';

export const Container = styled.div`
  margin-top: 50px;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px 0 20px;
  ${AppearAnimation}
  ${FadeAnimation}

  @media screen and (max-width: 294px) {
    margin-top: 0;
  }
`;

export const Title = styled.span`
  opacity: 0;
  display: block;
  ${({ theme }) => theme.font('font-headline-highcontrast-medium')};
  text-align: center;
  font-size: 24px;
  margin: 0 0 16px 0;
  color: ${({ theme }) => '#000'};
  word-break: break-word;

  ${({ animation }) =>
    animation.active
      ? `
            ${mergeAnimationUsesOptions([
              UseAppearFromBottomToTop.configure({
                delay: `${animation.delay}s`,
                duration: '.4s',
              }),
              UseFadeIn.configure({
                delay: `${animation.delay}s`,
                duration: '1s',
              }),
            ]).getAsCss()}
         `
      : ''}
`;

export const Subtitle = styled.span`
  display: block;
  opacity: 0;
  font-family: 'Segma-Regular';
  ${({ theme }) => theme.font('font-subtitle-midcontrast-sentence-large')};
  line-height: 20px;
  text-align: center;
  /* color: ${({ theme }) => theme.harmonization.colors.night_blue_2}; */
  word-break: break-word;
  ${({ animation }) =>
    animation.active
      ? `
  ${mergeAnimationUsesOptions([
    UseAppearFromBottomToTop.configure({
      delay: `${animation.delay}s`,
      duration: '.8s',
    }),
    UseFadeIn.configure({
      delay: `${animation.delay}s`,
      duration: '1s',
    }),
  ]).getAsCss()}
    `
      : ''}
`;
