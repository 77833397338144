export default function androidEvent(event) {
  const eventsInterface = getInterface();
  if (!eventsInterface) {
    // eslint-disable-next-line no-console
    console.warn('[ANDROID_SEND_EVENT_WARNING]', event);
    return;
  }
  eventsInterface.send(JSON.stringify(event));
}

function getInterface() {
  if (window.ReviewWebEvents) return window.ReviewWebEvents;
  return null;
}
