import { ButtonFactory } from '../Button';
import { BannerFactory } from '../Banner';
import { TextBlockFactory } from '../TextBlock';

const mapper = {
  factories: {
    completedOrderBanner: BannerFactory,
    textBlock: TextBlockFactory,
    button: ButtonFactory,
  },
  factory(type) {
    return type && this.factories[type];
  },
};

export default mapper;
