import React from 'react';
import { Spinner } from '@pedidosya/order-status-components';

const LoginPage = React.lazy(() => import('./Login'));

const LoginLoader = (props) => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <LoginPage {...props} />
    </React.Suspense>
  );
};

export default LoginLoader;
