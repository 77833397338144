import React, { useEffect, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@app/logger';
import MicrositeError from '@app/components/Microsite/MicrositeError';
import MicrositeLoader from '@app/components/Microsite/MicrositeLoader';
import isObject from '@commons/utils/object/isObject';

const SERVICE_KEY = '[EXTERNAL SERVICES PROVIDER]';
const ExternalServiceContext = createContext();

export const useExternalServices = () => useContext(ExternalServiceContext);

export const allServicesAreValidInstances = (services) => {
  return (
    services &&
    isObject(services) &&
    'fwf' in services &&
    isObject(services?.fwf) &&
    'tracker' in services &&
    isObject(services?.tracker)
  );
};

const ExternalServiceProvider = ({ children, externalServices }) => {
  const [services, setServices] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (externalServices && allServicesAreValidInstances(externalServices)) {
      getLogger().info(`${SERVICE_KEY} service instances obtained successfully.`);
      setServices(externalServices);
      setError(null);
    } else {
      const errorMessage = 'Some mandatory external services are not valid instances';
      getLogger().error(`${SERVICE_KEY} errorMessage`);
      setError(errorMessage);
    }
  }, [externalServices]);

  if (error) return <MicrositeError debugMessage={error} />;

  if (!services) return <MicrositeLoader />;
  return (
    <ExternalServiceContext.Provider value={services}>{children}</ExternalServiceContext.Provider>
  );
};

ExternalServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  externalServices: PropTypes.object.isRequired,
};

export default ExternalServiceProvider;
