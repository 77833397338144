import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Helmet from 'react-helmet';
import { useWindow } from '@app/providers/WindowProvider';
import { usePublicEnv } from './providers/PublicEnv';
import MainRouter from './MainRouter';
import { useExternalServices } from '@app/providers/ExternalServiceProvider/ExternalServiceProvider';
import { getLogger } from '@app/logger';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(props) {
  const logger = getLogger();
  const context = usePublicEnv();
  logger.info(
    `App loaded. ENV:'${context?.environment}' COUNTRY: ${context?.country?.shortName} VERSION:'${context?.app?.appVersion}' PLATFORM:'${context?.app?.platformKey}' DEVICE:'${context?.device}'`,
  );
  const { tracker } = useExternalServices();
  const globalObject = useWindow();

  if (!globalObject) {
    return null;
  }

  return (
    <Router isServer={false}>
      <QueryClientProvider client={queryClient}>
        <Helmet title="Delivery de Comida Online - Elegí, Pedí y Ya | PedidosYa" />
        <Helmet
          meta={[
            {
              name: 'description',
              content: 'PedidosYa - Detalle de la orden',
            },
          ]}
        />
        <MainRouter tracker={tracker} context={props?.context} flags={props?.fwfFlags} />
      </QueryClientProvider>
    </Router>
  );
}

App.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
};

export default App;
