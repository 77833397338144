const fetcher = (url, token) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', token);
  return fetch(url, { headers })
    .then(res => {
      return res.json()
    });
};

export default fetcher;
