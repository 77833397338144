async function reviewClicked(context, payload) {
  return {
    ...context,
    shopName: payload.shopName,
    shopId: payload.shopId,
    businessType: payload.businessType,
  };
}

export default reviewClicked;
