import { initializeInstance as initializeFWF } from '@commons/fwf/instance';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';
export const initFWFService = async (fwfPromise, context) => {
  if (!(fwfPromise instanceof Promise)) {
    throw new Error('Invalid service promise: fwfPromise');
  }

  const response = Response();

  try {
    const userData = { userId: context?.app?.user?.id, userEmail: context?.app?.user?.email };
    //TODO: check this, if we wait for it here, android crashes (I think because it does not have enabled local/session storage in the permissions).
    const fwf = await initializeFWF(fwfPromise, {
      sessionId: context?.app?.sessionId,
      userData,
      contextData: {
        country: context?.app?.country?.shortName,
        // city: undefined,
        device: context?.device || 'DESKTOP',
      },
    });

    response.setStatus(true).setData(fwf).setCode(MICROSITE_STATE.SUCCESS);
  } catch (error) {
    response.setError(error.message).setCode(MICROSITE_STATE.ERROR);
  }

  return response.toObject();
};
