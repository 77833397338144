import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AnimationSecuence from '@app/components/AnimationSecuence';
// TODO: delete this import
import pageEventService, { PAGE_EVENT } from '@app/services/page-event-service';
import MicrositeLoader from '@app/components/Microsite/MicrositeLoader';
import logo from './lotties/default.json';
import { Container, BackgroundContainer, Media } from './Banner.style';
import lottieConfig from './lottieConfig';
function Banner({ styles, lottieToken, countryId } = { styles: { backgroundColor: '#67c3d2' } }) {
  const [leavingPage, setLeavingPage] = useState(false);
  const [lottieData, setLottieData] = useState(null);

  useEffect(() => {
    let lottieInfo = lottieConfig.default;
    if (lottieToken === 'country') {
      if (lottieConfig[countryId]) {
        lottieInfo = lottieConfig[countryId];
      }
    }

    lottieInfo
      .data()
      .then((json) => setLottieData({ ...lottieInfo, data: json }))
      .catch(() => {
        setLottieData({ ...lottieInfo, data: logo });
      });
  }, [lottieToken, countryId]);

  const onLeavePage = useCallback(
    () =>
      new Promise((resolve) => {
        setLeavingPage(true);
        setTimeout(() => {
          resolve();
        }, 400);
      }),
    [],
  );
  useEffect(() => {
    pageEventService.registerHandler(PAGE_EVENT.BEFORE_UNLOAD_PAGE, onLeavePage);

    return () => {
      pageEventService.unregisterHandler(PAGE_EVENT.BEFORE_UNLOAD_PAGE, onLeavePage);
    };
  }, [onLeavePage]);

  useEffect(() => setLeavingPage(false), []);

  if (!lottieData) {
    return <MicrositeLoader />;
  }

  const { backgroundColor } = styles;

  let slideDirection = 0;

  let animationFrames = lottieData.animations.in;

  if (leavingPage) {
    slideDirection = -1;
    animationFrames = lottieData.animations.out;
  }

  const classes = `bg ${leavingPage ? 'slideUp' : 'slideDown'}`;

  return (
    <Container background={backgroundColor}>
      <BackgroundContainer slideDirection={slideDirection}>
        <div className={classes}></div>
      </BackgroundContainer>
      <Media>
        {/* <img src={image} alt="icon" /> */}
        <AnimationSecuence play animationData={lottieData.data} frameSegments={animationFrames} />
      </Media>
    </Container>
  );
}

Banner.propTypes = {
  // image: PropTypes.string,
  styles: PropTypes.object,
};

export default Banner;
