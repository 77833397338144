import mapper from './mapper';

const factory = {
  mapper,
  create(brickData) {
    const { type } = brickData;
    const factoryElement = this.mapper.factory(type);

    if (factoryElement === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `WARNING - componentType [${type}] is not defined in Mapper, [data] ${JSON.stringify(
          brickData,
        )}`,
      );
      return null;
    }

    return factoryElement({ brickData });
  },
};

export default factory;
