import { getRequestService } from '@app/services/request';
import isObject from '@commons/utils/object/isObject';
import pkg from '../../../../../package.json';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';

const isUnauthorizedError = (error) => {
  return isObject(error) && (error?.response?.status === 401 || error?.response?.status === 403);
};
const getErrorResponseBody = (error) => {
  return isObject(error) && (error?.response?.data || {});
};

const getApiBaseUrl = () => {
  return `/${pkg.name}/api`;
};

export const initPublicEnv = async (orderId, countryId) => {
  const response = new Response();
  try {
    const queryParams = new URLSearchParams({
      orderId,
      countryId,
    });
    const apiBaseUrl = getApiBaseUrl();
    const url = `${apiBaseUrl}/env?${queryParams.toString()}`;
    const service = getRequestService();
    const { data: ApiResponse } = await service.get(url);
    if (ApiResponse.success) {
      response
        .setStatus(true)
        .setData({ ...ApiResponse.data, apiBaseUrl: apiBaseUrl })
        .setCode(MICROSITE_STATE.SUCCESS);
    } else {
      response.setStatus(false).setError(ApiResponse.message).setCode(MICROSITE_STATE.ERROR);
    }
  } catch (e) {
    if (isUnauthorizedError(e)) {
      response
        .setStatus(true)
        .setData(getErrorResponseBody(e))
        .setCode(MICROSITE_STATE.UNAUTHORIZED);
    } else {
      response.setStatus(false).setError(e.message).setCode(MICROSITE_STATE.ERROR);
    }
  }
  return response.toObject();
};
