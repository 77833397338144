import React from 'react';
import PropTypes from 'prop-types';

import Banner from './Banner';

const propTypes = {
  brickData: PropTypes.object.isRequired,
};

function BannerFactory({ brickData }) {
  const { styles, data, customProps } = brickData;
  const lottieToken = customProps?.bannerTheme;

  return (
    <Banner
      title={data.vendorName}
      lottieToken={lottieToken}
      countryId={customProps?.countryId}
      image={data.imageUrl}
      order={data.order}
      styles={styles}
    />
  );
}

BannerFactory.propTypes = propTypes;

export default BannerFactory;
