import AnimationOptions from './AnimationOptions';

const DEFAULT_DURATION = '2s';

const animationLogic = `
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
`;
export const SlideAnimation = `
  @keyframes slideUp {
    ${animationLogic}
  }

  @keyframes slideDown {
    ${animationLogic}
  }
`;

export const UseSlideDown = new AnimationOptions({
  name: 'slideDown',
  duration: DEFAULT_DURATION,
});

export const UseSlideUp = new AnimationOptions({
  name: 'slideUp',
  duration: DEFAULT_DURATION,
  direction: 'reverse',
});
