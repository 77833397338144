export const FLAGS_COMPARE_FUNCTIONS = {
  BOOLEAN: (oldFlag, newFlag) => oldFlag.variation !== newFlag.variation,
  MULTIVARIATION: () => true,
};

export const FLAGS = {
  WEB_ENABLE_QATAR_THEME: {
    KEY: 'web-enable-qatar-theme',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.MULTIVARIATION,
    DEFAULT_VALUE: 'default',
    cacheable: false,
  },
};

const mapFlagToKey = (flag) => flag.KEY;

export const NON_CACHEABLE_FLAGS_KEYS = Object.values(FLAGS)
  .filter((flag) => 'cacheable' in flag && flag.cacheable === false)
  .map(mapFlagToKey);

export const FLAGS_KEYS = Object.values(FLAGS).map(mapFlagToKey);
