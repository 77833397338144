const commonAnimations = {
  in: [{ start: 0, end: 181, reverse: false, loop: false }],
  out: [{ start: 0, end: 67, speed: 3, reverse: true }],
};

const createLottieConfig = (fileName, animations = commonAnimations) => ({
  data: () => {
    return import(`./lotties/${fileName}.json`).then((response) => response.default);
  },
  animations,
});

const lottieConfig = {
  default: createLottieConfig('default', {
    in: [
      { start: 0, end: 70, reverse: false, loop: false },
      { start: 70, end: 181, loop: true },
    ],
    out: [{ start: 0, end: 67, speed: 3, reverse: true }],
  }),
  ar: createLottieConfig('ar'),
  ec: createLottieConfig('ec'),
  bo: createLottieConfig('bo'),
  cr: createLottieConfig('cr'),
  cl: createLottieConfig('cl'),
  uy: createLottieConfig('uy'),
};

export default lottieConfig;
