import React from 'react';
import PropTypes from 'prop-types';
import FenixButton from '@pedidosya/web-fenix/atoms/Button';
import { SIZE, VARIANT } from './constants';

const colorVariants = {
  primary: 'primary',
  secondary: 'secondary',
  // transparent está deprecado en web-fenix, pero se mantiene la key por retrocompatibilidad con OSME
  transparent: 'tertiary',
  tertiary: 'tertiary',
};

function Button({ text, style, icon, disabled, onClick }) {
  const { variant, size } = style;

  const getValidVariant = (colorVariant) => {
    return colorVariants[colorVariant] || 'primary';
  };

  return (
    <FenixButton
      icon={icon}
      label={text}
      size={size}
      disabled={disabled}
      hierarchy={getValidVariant(variant)}
      onClick={onClick}
    />
  );
}

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  style: PropTypes.shape({
    variant: PropTypes.oneOf(Object.values(VARIANT)),
    size: PropTypes.oneOf(Object.values(SIZE)),
    minWidth: PropTypes.string,
    fullWidth: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  disabled: false,
  style: {
    variant: 'primary',
    size: SIZE.LARGE,
    fullWidth: false,
  },
};

export default Button;
