import React from 'react';
import PropTypes from 'prop-types';

import TextBlock from './TextBlock';

const propTypes = {
  brickData: PropTypes.string,
};

function TextBlockFactory({ brickData }) {
  const { data, order } = brickData;
  const animationConfig = {
    active: true,
    delay: order / 2 || 0,
  };
  return <TextBlock title={data.title} subtitle={data.subtitle} animation={animationConfig} />;
}

TextBlockFactory.propTypes = propTypes;

export default TextBlockFactory;
