import androidInterface from './platforms/androidInterface';
import iosTrack from './platforms/iosInterface';

import osDeliveredLoaded from './actions/osDeliveredLoaded';
import onlineHelpClicked from './actions/onlineHelpClicked';
import reviewClicked from './actions/reviewClicked';

import { ORDER_STATUS_DELIVERED_LOADED, REVIEW_CLICKED, ONLINE_HELP_CLICKED } from './events';

const trackingManager = {
  context: {
    origin: null,
    orderId: null,
  },
  platform: null,
  tracker: null,
  push: {
    ANDROID: androidInterface,
    IOS: iosTrack,
  },
  actions: {
    [ORDER_STATUS_DELIVERED_LOADED]: osDeliveredLoaded,
    [REVIEW_CLICKED]: reviewClicked,
    [ONLINE_HELP_CLICKED]: onlineHelpClicked,
  },
  setContext(context, { platform = '', tracker = null }) {
    this.context = {
      ...context,
    };
    this.platform = platform.toUpperCase();
    this.tracker = tracker;
  },
  async send(eventName, payload) {
    if (!this.context.orderId || !this.platform)
      throw new Error('[TrackingManager] the context was not initialized');

    const dimensions = await this.actions[eventName](this.context, payload);

    const data = {
      event: eventName,
      payload: { ...dimensions },
    };

    if (this.push[this.platform]) {
      this.push[this.platform](data);
    } else if (this.tracker) {
      this.tracker.track(data.event, data.payload);
    }

  },
};

export default trackingManager;
