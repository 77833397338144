/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext, useMemo } from 'react';
import axios from 'axios';
import pkg from '../../../package.json';
import isObject from '@commons/utils/object/isObject';
import MicrositeError from '@app/components/Microsite/MicrositeError';
import { getLogger } from '@app/logger';
import { getRequestService } from '@app/services/request';

const SERVICE_KEY = `[PUBLIC ENV]`;

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await getRequestService().get(`/api/${pkg.name}/env`);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, loader, debugMode = false }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isObject(context)) {
      getLogger().info(`${SERVICE_KEY} public env obtained successfully`);
      const appContext = { ...context, debugMode: Boolean(debugMode) };
      setPublicEnv(appContext);
      setLoading(false);
      setError(null);
    } else {
      setError(`Invalid public env ${context}`);
      setLoading(false);
    }
  }, [debugMode, context]);

  if (error) {
    getLogger().error(`${SERVICE_KEY} error getting public env: ${error}`);
    return <MicrositeError debugMessage={error} />;
  }

  if (loading) {
    return loader || null;
  }

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  debugMode: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
