import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@app/logger';
import LoginLoader from '@app/components/Login/LoginLoader';

const SERVICE_KEY = '[AUTH]';

function isUserLogged(userId, userEmail) {
  return userId && userEmail;
}

function Authentication({ children, context }) {
  const [userLogged, setUserLogged] = useState(
    isUserLogged(context?.app?.user?.id, context?.app?.user?.email),
  );

  useEffect(() => {
    const authenticated = isUserLogged(context?.app?.user?.id, context?.app?.user?.email);

    if (authenticated) {
      setUserLogged(true);
      getLogger().info(
        `${SERVICE_KEY}[country: ${context?.country?.shortName}][device: ${context?.device}][platformKey: ${context?.app?.platformKey}] user logged.`,
      );
    } else {
      setUserLogged(false);
      getLogger().info(
        `${SERVICE_KEY}[country: ${context?.country?.shortName}][device: ${context?.device}][platformKey: ${context?.app?.platformKey}] user not logged.`,
      );
    }
  }, [context]);

  return userLogged ? children : <LoginLoader />;
}

Authentication.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.object.isRequired,
};

export default Authentication;
