import axios from 'axios';
import axiosRetry from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed'; // Throw ERR_REQUIRE_ESM error

let instance = null;
let initialized = false;

function isNetworkError(error) {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

function errorResponseHandler(error) {
  try {
    const { message } = error;
    const data = error.response?.data || {};
    const url = error.config?.url;
    const status = error.response?.status || 'unknown';

    if (isNetworkError(error)) {
      return Promise.reject(error);
    }

    const dataToLog = JSON.stringify({
      status,
      url,
      message,
      data,
    });

    // eslint-disable-next-line no-console
    console.log(`[AXIOS_ERROR_INTERCEPTOR] ${dataToLog}`);

    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
}

const errorInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use((response) => response, errorResponseHandler);
};

const retryInterceptor = (axiosInstance) =>
  axiosRetry(axiosInstance, {
    retries: 3,
    // only retries on internet connection errors
    retryCondition: isNetworkError,
    retryDelay: axiosRetry.exponentialDelay,
  });

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();

  retryInterceptor(instance);
  errorInterceptor(instance);

  return instance;
}

export function initRequestService({ defaultHeaders = {} }) {
  if (!initialized) {
    initialized = true;
    initialize({ headers: {} });
  }
}

export function getRequestService() {
  return instance;
}
