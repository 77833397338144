export default function iosTrack(data) {
  const trackingInterface = getInterface();
  if (!trackingInterface) {
    // eslint-disable-next-line no-console
    console.info('[iOS_TRACKING_WARNING]', data);
    return;
  }
  trackingInterface.postMessage(data);
}

function getInterface() {
  if (window?.webkit?.messageHandlers?.AnalyticsWebInterface)
    return window.webkit.messageHandlers.AnalyticsWebInterface;

  return null;
}
