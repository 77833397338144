import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

const AnimationContainer = styled.div`
  & svg {
    width: auto !important;
    height: auto !important;
  }
`;

const AnimationSecuence = ({ animationData, frameSegments, play, styles }) => {
  const lottieRef = useRef(null);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [animationOptions] = useState({
    animationData,
    autoplay: false,
    loop: 0,
  });

  const getFrame = (segmentData) => {
    let start = 'start';
    let end = 'end';
    if (segmentData.reverse) {
      start = 'end';
      end = 'start';
    }

    return [segmentData[start] || 0, segmentData[end]];
  };

  const shouldUpdateAnimation = useCallback(
    () => lottieRef && lottieRef.current && play && frameSegments && frameSegments.length > 0,
    [lottieRef, play, frameSegments],
  );

  const onAnimationFinished = useCallback(() => {
    const currentSegmentData = frameSegments[currentSegment];
    if (currentSegmentData.loop) {
      const frame = getFrame(currentSegmentData);
      lottieRef.current.playSegments(frame, true);
    } else {
      const nextSegment = currentSegment + 1;
      if (nextSegment < frameSegments.length) {
        setCurrentSegment(nextSegment);
      }
    }
  }, [currentSegment, frameSegments]);

  useEffect(() => {
    shouldUpdateAnimation({
      animationData,
      autoplay: false,
      loop: 0,
    });
    setCurrentSegment(0);
  }, [frameSegments, animationData]);

  useEffect(() => {
    if (shouldUpdateAnimation()) {
      const currentSegmentData = frameSegments[currentSegment];
      if (currentSegmentData) {
        lottieRef.current.setDirection(currentSegmentData.reverse ? -1 : 1);
        lottieRef.current.setSpeed(currentSegmentData.speed || 1);

        lottieRef.current.playSegments(getFrame(currentSegmentData), true);
      }
    }
  }, [currentSegment, animationData, play, frameSegments, shouldUpdateAnimation]);

  return (
    <AnimationContainer className="animation-secuence" style={styles}>
      <Lottie lottieRef={lottieRef} onComplete={onAnimationFinished} {...animationOptions} />
    </AnimationContainer>
  );
};

AnimationSecuence.propTypes = {
  animationData: PropTypes.object.isRequired,
  frameSegments: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
      loop: PropTypes.bool,
      reverse: PropTypes.bool,
      speed: PropTypes.number,
    }),
  ).isRequired,
  play: PropTypes.bool,
  styles: PropTypes.object,
};

AnimationSecuence.defaultProps = {
  styles: {},
  play: true,
};

export default AnimationSecuence;
