export default function androidTrack(data) {
  const trackingInterface = getInterface();
  if (!trackingInterface) {
    // eslint-disable-next-line no-console
    console.info('[ANDROID_TRACKING_WARNING]', data);
    return;
  }
  trackingInterface.logEvent(data.event, JSON.stringify(data.payload));
}

function getInterface() {
  if (window?.AnalyticsWebInterface) return window?.AnalyticsWebInterface;
  return null;
}
