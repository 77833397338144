import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useRoutes } from '@app/providers/CrossDeviceRoutesProvider';
import CompletedOrderModule from '@app/pages/completedOrder/container/CompletedOrder';
import eventManager from '@app/pages/completedOrder/events';
import MicrositeError from './components/Microsite/MicrositeError';
import trackingManager from '@app/pages/completedOrder/tracking';
import pkg from '../package.json';

function Router({ tracker, context, flags }) {
  const queryParams = context?.queryParams;
  //TODO: legacy, refactor this
  eventManager.init({ platform: context?.app?.platformKey });

  trackingManager.setContext(
    {
      origin: queryParams?.origin || 'not set',
      orderId: queryParams?.orderId || 'not set',
    },
    {
      platform: context?.app?.platformKey,
      tracker,
    },
  );
  // refactor end.

  const ROUTES = useRoutes();
  const webPublicUrl = `${ROUTES.MWEB_COMPLETED_ORDER}`;
  const defaultPath =
    window.location.pathname === webPublicUrl
      ? webPublicUrl
      : [ROUTES.COMPLETED_ORDER, `/${pkg.name}${ROUTES.COMPLETED_ORDER}`];

  return (
    <Switch>
      <Route
        exact
        path={defaultPath}
        render={(props) => (
          <CompletedOrderModule
            flags={flags}
            tracker={tracker}
            context={context}
            queryParams={queryParams}
            {...props}
          />
        )}
      />

      <Route
        render={() => (
          <MicrositeError debugMessage={`page not found: ${window.location.pathname}`} />
        )}
      />
    </Switch>
  );
}

Router.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.object.isRequired,
};

export default Router;
