import redirectOperation from './operations/redirect';
import trackingOperation from './operations/tracking';

const actionsManager = {
  operations: {
    redirect: redirectOperation,
    tracking: trackingOperation,
  },
  buildHandler(actions) {
    const handlerActions = [];
    const { operations } = this;
    actions.forEach(action => {
      const { payload, type } = action;
      const { context } = payload;

      const actionInfo = {
        context,
        payload,
        execute() {
          return operations[type](this.payload, context);
        },
      };

      handlerActions.push(actionInfo.execute.bind(actionInfo));
    });

    return createStepActions(handlerActions).execute;
  },
};

function createStepActions(actions) {
  return {
    actions,
    async execute() {
      // execute action by action, stop if almost one fails
      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        // eslint-disable-next-line no-await-in-loop
        if (!(await action())) break;
      }
    },
  };
}

export default actionsManager;
