import AnimationOptions from './AnimationOptions';

const DEFAULT_DURATION = '1s';

export const FadeAnimation = `
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const UseFadeIn = new AnimationOptions({
  name: 'fade',
  duration: DEFAULT_DURATION,
  'fill-mode': 'forwards',
});

export const UseFadeOut = new AnimationOptions({
  name: 'fade',
  duration: DEFAULT_DURATION,
  direction: 'reverse',
  'fill-mode': 'forwards',
});
