/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AnimationOptions from './AnimationOptions';

const Animation = styled.div`
  ${props => props.animationDefinitions}
  ${props => props.animationUses}
`;

const accumulateOptionValuesForEachAnimationAttribute = (
  animationOptions,
  accumulator,
  isLastElement,
) =>
  Object.entries(animationOptions).reduce((currentOptions, [key, value]) => {
    if (!currentOptions[key]) {
      currentOptions[key] = '';
    }
    currentOptions[key] += `${
      value ? String(value) + (isLastElement ? '' : ', ') : ''
    }`;

    return currentOptions;
  }, accumulator);

export const mergeAnimationUsesOptions = animationUses => {
  if (Array.isArray(animationUses) && animationUses.length > 0) {
    const lasAnimationIndex = animationUses.length - 1;
    return new AnimationOptions(
      animationUses
        .map(animationUse => animationUse.getAsObject())
        .reduce(
          (mergedAnimations, animationOptions, index) =>
            accumulateOptionValuesForEachAnimationAttribute(
              animationOptions,
              mergedAnimations,
              index === lasAnimationIndex,
            ),
          {},
        ),
    );
  }

  return new AnimationOptions(animationUses);
};

const isNonEmptyArray = data => Array.isArray(data) && data.length > 0;

const AnimatedElement = ({
  animationDefinitions,
  animationUses,
  styles,
  children,
}) => {
  if (
    !isNonEmptyArray(animationDefinitions) ||
    !isNonEmptyArray(animationUses)
  ) {
    return null;
  }
  const animationDefinitionCss = animationDefinitions.join('');
  const animationUsesCss = mergeAnimationUsesOptions(animationUses).getAsCss();

  return (
    <Animation
      style={styles}
      animationDefinitions={animationDefinitionCss}
      animationUses={animationUsesCss}
    >
      {children}
    </Animation>
  );
};

AnimatedElement.propTypes = {
  animationDefinitions: PropTypes.arrayOf(PropTypes.string).isRequired,
  animationUses: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
  styles: PropTypes.object,
};

AnimatedElement.defaultProps = {
  styles: {},
};

export default AnimatedElement;
