import AnimationOptions from './AnimationOptions';

const DEFAULT_DURATION = '1s';

export const AppearAnimation = `
  @keyframes appear-bottom-top {
    from {
      position: relative;
      top: 15px;
    }
    to {
      position: relative;
      top: 0px;
    }
  }

  @keyframes appear-top-bottom {
    from {
      position: relative;
      bottom: 15px;
    }
    to {
      position: relative;
      bottom: 0px;
    }
  }

  @keyframes appear-anchored-bottom-top {
    from {
      position: absolute !important;
      bottom: -10%;
    }
    to {
      position: absolute !important;
      bottom: 2%;
    }
  }

  @keyframes appear-anchored-top-bottom {
    from {
      position: absolute;
      top: -15px;
    }
    to {
      position: absolute;
      top: 0px;
    }
  }
`;

export const UseAppearFromBottomToTop = new AnimationOptions({
  name: 'appear-bottom-top',
  duration: DEFAULT_DURATION,
});

export const UseAppearFromAnchoredBottomToTop = new AnimationOptions({
  name: 'appear-anchored-bottom-top',
  duration: DEFAULT_DURATION,
});

export const UseFadeOut = new AnimationOptions({
  name: 'appear-top-bottom',
  duration: DEFAULT_DURATION,
});
