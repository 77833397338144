import { getLogger } from '@app/logger';
import androidEvents from './platforms/androidEvents';
import iosEvents from './platforms/iosEvents';
import webEvent from './platforms/webEvents';

const SERVICE_KEY = '[EventManager]';

const EventManager = {
  platform: null,
  send: {
    ANDROID: androidEvents,
    ANDROID_NIGHT: androidEvents,
    HUAWEI: androidEvents,
    HUAWEI_NIGHT: androidEvents,
    IOS: iosEvents,
    IOS_NIGHT: iosEvents,
    DEFAULT: webEvent,
  },
  init({ platform }) {
    this.platform = platform.toUpperCase();
  },
  dispatch(action, data) {
    if (!this.platform) {
      throw new Error(`${SERVICE_KEY} It was not initialized`);
    }

    const event = { event: action, payload: data };
    if (this.send[this.platform]) {
      this.send[this.platform](event);
    } else if (this.send['DEFAULT']) {
      getLogger().error(`${SERVICE_KEY} using default platform ${this.platform}`);
      this.send['DEFAULT'](event);
    } else {
      getLogger().error(`${SERVICE_KEY} Invalid platform ${this.platform}`);
    }
  },
};

export default EventManager;
