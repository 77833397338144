import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Subtitle } from './TextBlock.style';

function TextBlock({ title, subtitle, animation }) {
  return (
    <Container>
      {title && <Title animation={animation}>{title}</Title>}
      {subtitle && <Subtitle animation={animation}>{subtitle}</Subtitle>}
    </Container>
  );
}

TextBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  animation: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
  }).isRequired,
};

export default TextBlock;
