import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';

export const initTrackerService = async (trackerPromise) => {
  if (!(trackerPromise instanceof Promise)) {
    throw new Error('Invalid service promise: trackerPromise');
  }

  const response = new Response();

  try {
    const tracker = await trackerPromise;
    if (!tracker?.track || typeof tracker.track !== 'function') {
      throw new Error('Invalid tracker service instance');
    }
    response.setStatus(true).setData(tracker).setCode(MICROSITE_STATE.SUCCESS);
  } catch (error) {
    response.setError(error.message).setCode(MICROSITE_STATE.ERROR);
  }
  return response.toObject();
};
