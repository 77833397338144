import { isEmpty } from '@app/utils/string';
export function isMobile(appId) {
  if (!appId || isEmpty(appId)) {
    return false;
  }

  const identifier = appId.trim().toLowerCase();
  return identifier.includes('monolith')
    ? false
    : identifier.includes('ios') || identifier.includes('android') || identifier.includes('huawei');
}
