import { Spinner } from '@pedidosya/order-status-components';
import React from 'react';

const ErrorPageComponent = React.lazy(() => import('./ErrorPage'));

const ErrorPageLoader = (props) => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <ErrorPageComponent {...props} />
    </React.Suspense>
  );
};

export default ErrorPageLoader;
