import { getFWFToken, getFWFUrl } from './config';
import { getEnvironment, isLocal } from '@app/utils/environment';
import { Flags } from '@commons/fwf/models/Flags';

import { FLAGS_KEYS } from './constants';

const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';
// change manualy to true to enable SDK debug mode
const DEBUG_MODE = isLocal() && false;

let initializationPromise;

function getUser({ sessionId, userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };
  if (sessionId) user.googleClientId = sessionId;
  const { userId, userEmail, ...userDataRest } = userData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

function importSdk() {
  return import(/* webpackChunkName: "fwf-sdk" */ '@fwf/fwf-sdk-javascript');
}

export async function initializeInstance(
  fwfPromise,
  { sessionId, userData, contextData },
  onUpdate,
) {
  initializationPromise = fwfPromise;

  const fwf = await fwfPromise;

  const user = getUser({ sessionId, userData, contextData });
  fwf.setContext(user);
  fwf.subscribeFeatures(FLAGS_KEYS);
  fwf.subscribeObserver((response) => {
    if (
      response.type === FLAGS_UPDATED_EVENT_NAME ||
      response.type === CONTEXT_UPDATED_EVENT_NAME
    ) {
      const flags = Flags(response.data);
      if (typeof onUpdate === 'function') {
        onUpdate(flags);
      }
    }
  });

  return initializationPromise;
}

export async function getServerInstance({
  sessionId,
  userData,
  contextData = {},
  env,
  fwfUrl,
  fwfToken,
  flagTTLMinutes = 20,
}) {
  const user = getUser({ sessionId, userData, contextData });
  const { default: FWFMain, LogLevel } = await importSdk();

  const ENV = env && typeof env === 'string' && env.length > 0 ? env : getEnvironment();
  const fwfParams = {
    environmentToken: fwfToken || getFWFToken(ENV),
    flagTimeToLive: flagTTLMinutes || 20, //minutes
    region: 'us',
    localFwfUrl: fwfUrl || getFWFUrl(ENV),
    debugMode: DEBUG_MODE,
  };

  const fwf = new FWFMain({ ...fwfParams, user });
  fwf.setLogLevel(LogLevel.ERROR);
  return fwf;
}

export function getClientInstance() {
  if (!initializationPromise) throw new Error('[FWF] must call initializeInstance before get one');

  return initializationPromise;
}

export async function updateUser({ sessionId, userData, contextData = {} }) {
  const instance = await getClientInstance();
  const user = getUser({ sessionId, userData, contextData });
  return instance.setContext(user);
}
