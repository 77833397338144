import React from 'react';
import WindowProvider from '@app/providers/WindowProvider';
import ErrorBoundary from '@app/components/ErrorBoundary';
import { ThemeProvider } from '@pedidosya/order-status-components';

const MicrositeWrapper = ({ children, platform }) => {
  return (
    <ErrorBoundary>
      <WindowProvider>
        <ThemeProvider platoform={platform}>{children}</ThemeProvider>
      </WindowProvider>
    </ErrorBoundary>
  );
};

export default MicrositeWrapper;
