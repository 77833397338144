import React from 'react';
import PropTypes from 'prop-types';
import App from './App';

import MicrositeInitializer from './components/Microsite/MicrositeInitializer';
import { registerCustomFont } from '@pedidosya/order-status-components';
import CUSTOM_FONT_TOKEN from './constants/custom-font-token';

const Root = (shellData) => {
  registerCustomFont(CUSTOM_FONT_TOKEN.ERROR_PAGE_TITLE, {
    size: 20,
    weight: 800,
    lineHeight: 1.17,
  });
  return <MicrositeInitializer shellData={shellData} AppComponent={App} />;
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.object,
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
